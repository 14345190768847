<template>
  <ion-button
    expand="block"
    :class="[`btn-${variant}`, `size-${size}`]"
    :disabled="disabled"
    :button-type="buttonType"
    @click.stop="onClick"
    @mousedown="onMousedown"
  >
    <div class="btn-content">
      <div class="btn-icon">
        <slot v-if="!loading" name="icon" class="logo"></slot>
      </div>
      <span v-if="!loading" class="btn-text" :style="textColor ? `color: ${textColor}` : ''">{{text}}</span>
      <ion-spinner v-else name="lines" :color="spinnerColor" class="spinner"></ion-spinner>
    </div>
  </ion-button>
</template>

<script>
import { IonButton } from '@ionic/vue';

export default {
  name: 'Button',

  components: {
    IonButton,
  },

  props: {
    text: { type: String, default: '' },
    textColor: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    variant: { type: String, default: 'primary' },
    size: { type: String, default: 'predefined' },
    buttonType: { type: String, default: 'button' },
    spinnerColor: { type: String, default: 'primary'}
  },

  emits: ['click', 'mousedown'],

  methods: {
    onClick() {
      this.$emit('click');
    },
    onMousedown() {
      this.$emit('mousedown');
    },
  },
};
</script>

<style scoped lang="scss">
ion-button {
  --border-radius: 100px;
  --box-shadow: none;
  text-transform: none;
}
.submit {
  --color: white;
}
.btn-primary {
  --background: #4064c0;
  --background-hover: #181f36;
}
.btn-secondary {
    --background: #ffffff;
    --background-hover: #4064c0;
    --border-color: #181f3610;
    --border-width: 1px;
    --border-style: solid;
    margin-top: 12px;
    color: #212a4a;
    font-size: 17px;
    font-weight: 550;
  }

  .btn-primary-bold{
    transition: var(--background);
    transition-duration: 1s;
    --background: #4064c0;
    --background-hover: #181f36;
    span{
      transition: all;
      transition-duration: 1s;
      color: white;
      font-size: 20px !important;
      font-weight: 700 !important;
      text-align: center;
    }
  }

  .btn-dark-blue {
    --background: #323F68;
    --background-hover: #181f36;
  }

  .btn-gray{
    transition: var(--background);
    transition-duration: 1s;
    --background: rgba(32, 40, 64, 0.06);
     span{
       transition: all;
      transition-duration: 1s;
       color: #181f36;
       font-weight: 600;
       font-size: 18px;
     }
  }
  .btn-gray:hover{
    transition: var(--background);
    transition-duration: 1s;
    --background:#4064c0;
    span{
      transition: all;
      transition-duration: 1s;
      color: white;
    }
  }

  .btn-icon {
    margin-right: 10px;
  }

ion-button.size-predefined {
  width: 600px;
  height: 60px;
  .btn-text {
    margin-left: 15px;
  }
  .btn-content {
    margin-left: 40px;
    margin-right: 40px;
    display: flex !important;
    align-items: center;
    justify-content: center;

  }
}

ion-button.size-auto {
  width: min-content;
  height: 40px;
  text-align: center;

}

ion-button.size-medium {
  width:156px;
  height: 32px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;

}
</style>
