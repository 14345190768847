<template>
  <ion-footer class="ion-no-border">
    <ion-toolbar>
      <p class="footer-text">
        Has your church not signed up with Tithely yet?
        <span class="signup-text">Sign up here</span>
      </p>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
  import { IonFooter, IonToolbar } from '@ionic/vue';

  export default {
    components: {
      IonFooter,
      IonToolbar,
    },
  };
</script>

<style scoped lang="scss">
  ion-footer {
    --background: #e2e4ec;
    position: absolute;
    bottom: 0;

    ion-toolbar {
      --background: #e2e4ec;
      text-align: center;

      .footer-text {
        color: #B8C0D9;
      }
      .signup-text {
        color: #4064c0;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
</style>
