<template>
  <ion-page :key="componentKey">
    <ion-content id="main-content" :fullscreen="true">
      <div class="container">
        <h1 @click="loading = !loading">Change manned station</h1>
        <span class="subtitle">
          Choose which location you are setting up at:
        </span>
        <ion-spinner
          v-if="loading"
          name="lines"
          color="primary"
        ></ion-spinner>
        <div v-else class="buttonBox">
          <Button
            v-for="(location, index) in locations"
            :text="location.name"
            variant="secondary"
            :key="index"
            @click="selectLocation(location)"
          />
        </div>
      </div>
    </ion-content>

    <Footer />
  </ion-page>
</template>

<script>
  import ViewMixin from '@/mixins/views';
  import { mapActions, mapMutations, mapState } from 'vuex';
  import { IonPage, IonContent } from '@ionic/vue';

  import Footer from '@/components/layout/Footer';
  import Button from '@/components/shared/buttons/Button';

  export default {
    components: {
      IonContent,
      IonPage,
      Footer,
      Button,
    },

    data() {
      return {
        loading: true,
      };
    },
    mixins:[ViewMixin],
    computed: {
      ...mapState({
        locations: (state) => state.locations.items,
        user: (state) => state.auth.user,
      }),
    },
    ionViewDidEnter(){
      this.componentKey++;
    },
    mounted() {
      this.getLocations(this.user.currentOrganizationId).then(() => {
        this.loading = false;
      });
    },

    methods: {
      ...mapActions({
        getLocations: 'locations/get',
      }),

      ...mapMutations({
        setCurrent: 'locations/SET_CURRENT',
      }),

      selectLocation(location) {
        this.setCurrent(location);
        this.$router.push({ name: 'Settings', params: { id: location.id } });
      },
    },
  };
</script>

<style scoped lang="scss">
  ion-content {

    .container {
      margin-top: 124px;
      h1 {
        margin-bottom: 24px;
      }
      .buttonBox {
        ion-button {
          margin-top: 48px;
          margin-bottom: 12px;
        }
      }
    }
  }
</style>
